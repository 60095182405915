import React, { useState, useEffect } from "react";
import "./forminfo.css";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import MapAddress from "./MapAddress/MapAddress";
import { DatePicker, TimePicker } from "antd";
import Eye from "../img/eye.svg";
import Counter from "./Counter/counter";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import {
  updateReservation,
  updateReservation2,
  selectReservation,
  setValidationError,
  clearError,
  validateInitialReservation,
  selectReservationValidationErrors,
  selectRoundTrip,
  validateInitialRoundTrip,
  setValidationError2,
  selectRoundTripValidationErrors,
  selectSingleReservation,
} from "../../redux/feature/reservation/slice";
import { fetchOrderTypes, selectOrderTypes } from "../../redux/feature/vehicle/slice";
import Swal from "sweetalert2";
import ValidateMsg from "./ValidateMsg/ValidateMsg";
import Select from "react-select";
import Papa from "papaparse";
import { Dropdown, Button, Menu, Modal } from "antd";
import { Select as AntdSelect } from 'antd';
function FormContainer({ goNext, hours, companyId }) {
  const dispatch = useDispatch();
  const reservation = useSelector(selectSingleReservation);
  const [tripType, setTripType] = useState("");
  const [orderType, setOrderType] = useState("Individual");
  const [duration, setDuration] = useState("");
  const [pickupDate, setPickupDate] = useState(new Date());
  const [pickupTime, setPickupTime] = useState(new Date());
  const [flight, setFlight] = useState("");
  const [airline, setAirline] = useState("");
  const [flightDropoff, setFlightDropoff] = useState("");
  const [airlineDropoff, setAirlineDropoff] = useState("");
  const [showFlightDetails, setShowFlightDetails] = useState(false);
  const [showFlightDetails2, setShowFlightDetails2] = useState(false);
  const [showFlightDetails3, setShowFlightDetails3] = useState(false);
  const [showFlightDetails4, setShowFlightDetails4] = useState(false);
  const [pickupAddress, setPickupAddress] = useState({});
  const [dropoffAddress, setDropoffAddress] = useState({});
  const [flightRT, setFlightRT] = useState("");
  const [airlineRT, setAirlineRT] = useState("");
  const [pickupAddressRT, setPickupAddressRT] = useState({});
  const [dropoffAddressRT, setDropoffAddressRT] = useState({});
  const [flightDropoffRT, setFlightDropoffRT] = useState("");
  const [airlineDropoffRT, setAirlineDropoffRT] = useState("");
  const [pickupType, setPickupType] = useState("");
  const [dropoffType, setDropoffType] = useState("");
  const [pickupTypeRT, setPickupTypeRT] = useState("");
  const [dropoffTypeRT, setDropoffRT] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // To store the selected image for popup
  const roundtrip = useSelector(selectRoundTrip);
  const orderTypes = useSelector(selectOrderTypes);
  const [orderTypeOption, setOrderTypeOption] = useState([]);
  const [openInput, setOpenInput] = useState(reservation?.orderType);
  const [openInput2, setOpenInput2] = useState(reservation?.orderType);
  const [openInput3, setOpenInput3] = useState(reservation?.orderType);
  const [openInput4, setOpenInput4] = useState(reservation?.orderType);

  useEffect(() => {
    dispatch(fetchOrderTypes(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    const orders = orderTypes.map((type) => ({
      value: type.name,
      label: type.name,
    }));
    setOrderTypeOption(orders);
  }, [orderTypes]);

  console.log("Order Types:", orderTypeOption);

  const handleTripTypeChange = (value) => {
    setTripType(value);
    handleChange("tripType", value);
  };

  // const handleOrderTypeChange = (event) => {
  //     setOrderType(event.target.value);
  //     handleChange("orderType", event.target.value);
  // };
  const handleOrderTypeChange = (value) => {
    const selectedOrderType = value;
    handleChange("orderType", selectedOrderType); // Update using handleChange
  };
  const handleDurationChange = (value) => {
    setDuration(value);
    handleChange("totalTripDuration", value);
    // handleChange("totalTripDuration", event.target.value*60);
  };

  const handleFlightChange = (event) => {
    setFlight(event.target.value);
  };

  const handleAirlineChange = (event) => {
    setAirline(event.target.value);
    handleChange("pickupAddress", {
      ...pickupAddress,
      airportInfo: {
        ...pickupAddress?.airportInfo,
        flightNumber: flight,
        airline: event.target.value,
        flightTime: pickupTime,
      },
    });
  };

  const handleFlightDropoffChange = (event) => {
    setFlightDropoff(event.target.value);
  };

  const handleDropOffAirlineChange = (event) => {
    setAirlineDropoff(event.target.value);
    handleChange("dropoffAddress", {
      ...dropoffAddress,
      airportInfo: {
        ...dropoffAddress?.airportInfo,
        flightNumber: flightDropoff,
        airline: event.target.value,
      },
    });
  };

  const [passengercount, setPassengercount] = useState(0);
  const [luggagecount, setLuggagecount] = useState(0);
  const [seatcount, setSeatcount] = useState(0);

  const [passengercount2, setPassengercount2] = useState(0);
  const [luggagecount2, setLuggagecount2] = useState(0);
  const [seatcount2, setSeatcount2] = useState(0);

  const errors = useSelector(selectReservationValidationErrors);
  const errors2 = useSelector(selectRoundTripValidationErrors);

  const onNextClick = () => {

    const errors = validateInitialReservation(reservation);
    const isDateTimeValid = compareDateTime(reservation.pickupDate, reservation.pickupTime);
    if (!isDateTimeValid) {
      return; // Prevent further execution
    }

    let roundTripErrors = {};

    if (reservation.tripType === "Round Trip") {
      roundTripErrors = validateInitialRoundTrip(roundtrip);
    }

    const allErrors = { ...errors, ...roundTripErrors };

    const Data = {
      tripType: tripType,
      orderType: orderType,
      duration: duration,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      pickupAddress: pickupAddress,
      dropoffAddress: dropoffAddress,
      flight: flight,
      flightDropoff: flightDropoff,
      passengercount: passengercount,
      luggagecount: luggagecount,
      seatcount: seatcount,
    };

    if (Object.keys(allErrors).length > 0) {
      dispatch(setValidationError(errors));
      if (reservation.tripType === "Round Trip") {
        dispatch(setValidationError2(roundTripErrors));
      }

      Swal.fire({
        icon: "error",
        title: "Validation Error",
        html: `<ul style="text-align: left; color: red; list-style-type: disc;">${Object.values(allErrors).map(error => `<li>${error}</li>`).join('')}</ul>`
      });

      return; // Prevent navigation to the next page
    } else {
      // Clear previous errors
      dispatch(clearError());

      // Allow navigation to the next page
      goNext(Data);
    }

  };

  const toggleFlightDetails = () => {
    setShowFlightDetails(!showFlightDetails);
  };

  const toggleFlightDetails2 = () => {
    setShowFlightDetails2(!showFlightDetails2);
  };
  const toggleFlightDetails3 = () => {
    setShowFlightDetails3(!showFlightDetails3);
  };
  const toggleFlightDetails4 = () => {
    setShowFlightDetails4(!showFlightDetails4);
  };

  const handleAddressChange = (field, value) => {
    setPickupAddress(value);
    handleChange("pickupAddress", value);
  };
  const handleDropoffChange = (field, value) => {
    setDropoffAddress(value);
    handleChange("dropoffAddress", value);
  };

  const handleAirportPickupChange = () => {
    setPickupType("airport");
    setShowFlightDetails(true);
    handleChange("orderType", "Airport Pickup");
  };

  const handlePickupAddressChange = () => {
    setPickupType("address");
    setShowFlightDetails(false);
  };

  const handleAirportDropOffChange = () => {
    setDropoffType("airport");
    setShowFlightDetails2(true);
    handleChange("orderType", "Airport Dropoff");
  };

  const handleDropOffAddressChange = () => {
    setDropoffType("address");
    setShowFlightDetails2(false);
  };

  // const handleCounterChange = (field, value) => {
  //     dispatch(updateReservation({field, value}));
  // };

  const handleChange = (field, value) => {
    dispatch(updateReservation({ field, value }));
  };

  //Round Trip Variables

  const handleRTAirportPickupChange = () => {
    setPickupTypeRT("airport");
    setShowFlightDetails3(true);
    handleChange2("orderType", "Airport Pickup");
  };

  const handleRTAddressPickupChange = () => {
    setPickupTypeRT("address");
    setShowFlightDetails3(false);
  };

  const handleRTAirportDropOffChange = () => {
    setDropoffRT("airport");
    setShowFlightDetails4(true);
    handleChange2("orderType", "Airport Dropoff");
  };

  const handleRTAddressDropOffChange = () => {
    setDropoffRT("address");
    setShowFlightDetails4(false);
  };

  const handleRTAddressChange = (field, value) => {
    setPickupAddressRT(value);
    handleChange2("pickupAddress", value);
  };
  const handleRTDropoffChange = (field, value) => {
    setDropoffAddressRT(value);
    handleChange2("dropoffAddress", value);
  };

  const handleRTFlightChange = (event) => {
    setFlightRT(event.target.value);
  };

  const handleRTAirlineChange = (event) => {
    setAirlineRT(event.target.value);
    handleChange2("pickupAddress", {
      ...pickupAddressRT,
      airportInfo: {
        ...pickupAddressRT?.airportInfo,
        flightNumber: flightRT,
        airline: event.target.value,
      },
    });
  };
  const handleModalClose = () => {
    setModalVisible(false); // Close the modal
    setSelectedImage(null); // Reset the selected image
  };
  const handleRTFlightDropoffChange = (event) => {
    setFlightDropoffRT(event.target.value);
  };

  const handleRTDropOffAirlineChange = (event) => {
    setAirlineDropoffRT(event.target.value);
    handleChange2("dropoffAddress", {
      ...dropoffAddressRT,
      airportInfo: {
        ...dropoffAddressRT?.airportInfo,
        flightNumber: flightDropoffRT,
        airline: event.target.value,
      },
    });
  };

  const handleCounterChange2 = (field, value) => {
    dispatch(updateReservation2({ field, value }));
  };

  const handleChange2 = (field, value) => {
    dispatch(updateReservation2({ field, value }));
  };

  const selectClass = tripType !== "Hourly" ? "wider-select" : "";
  const dateandtime = tripType !== "Hourly" ? "DateTime2" : "DateTime";
  const dateandtime2 = tripType !== "Hourly" ? "col-md-6" : "col-md-4";

  const currentDatePlusHours = dayjs().add(hours, "hour");
  const formattedCurrentDate = currentDatePlusHours.format("MM/DD/YYYY");
  const formattedCurrentTime = currentDatePlusHours.format("hh:mm A");

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("MM/DD/YYYY");
      console.log("Formatted date:", formattedDate);
      setPickupDate(formattedDate);
      handleChange("pickupDate", formattedDate);
      // compareDateTime(formattedDate, pickupTime);
    } else {
      setPickupDate(null);
      handleChange("pickupDate", null);
    }
  };

  const handleTimeChange = (time) => {
    if (time) {
      const formattedTime = dayjs(time).format("hh:mm A");
      console.log("Formatted time:", formattedTime);
      setPickupTime(formattedTime);
      handleChange("pickupTime", formattedTime);
      // compareDateTime(pickupDate, formattedTime);
    } else {
      setPickupTime(null);
      handleChange("pickupTime", null);
    }
  };

  const compareDateTime = (date, time) => {
    if (date && time) {
      const combinedDateTime = dayjs(`${date} ${time}`, "MM/DD/YYYY hh:mm A");
      const currentDate = dayjs().startOf("day");

      if (combinedDateTime.isBefore(currentDate)) {
        console.log("The selected date is a past date.");
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "The Selected Date & Time cannot be in the past.",
        });
        handleChange("pickupDate", null);
        handleChange("pickupTime", null);
        return false;
      } else if (combinedDateTime.isBefore(currentDatePlusHours)) {
        console.log(
          "The selected date and time is before the current date and time plus the given hours."
        );
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: `The Selected Date & Time Should Be More Than ${hours} Hours`,
        });
        handleChange("pickupDate", null);
        handleChange("pickupTime", null);
        return false;
      } else {
        console.log("The selected date and time is valid.");
        return true;
      }
    }
    return true;
  };

  const [airportData, setAirportData] = useState([]);
  const [airlinesData, setAirlinesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/Airlines.csv");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder("utf-8");
        const csv = decoder.decode(result.value);

        Papa.parse(csv, {
          header: true,
          dynamicTyping: true,
          complete: (parsedData) => {
            setAirlinesData(parsedData.data.slice(0, 1000));
            console.log("airlines", parsedData.data);
          },
        });
      } catch (error) {
        console.error("Error fetching and parsing data", error);
      }
    };

    fetchData();
  }, []);

  const formatAirlineOptions = (airlines) => {
    return airlines.map((airline) => ({
      value: airline.codeIcaoAirline,
      label: `${airline.nameAirline} (${airline.codeIcaoAirline})`,
    }));
  };

  const airlineOptions = formatAirlineOptions(airlinesData);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch('/Airports.csv');
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         const reader = response.body.getReader();
  //         const result = await reader.read();
  //         const decoder = new TextDecoder('utf-8');
  //         const csv = decoder.decode(result.value);

  //         Papa.parse(csv, {
  //           header: true,
  //           dynamicTyping: true,
  //           complete: (parsedData) => {
  //             setAirportData(parsedData.data);
  //             console.log('airports', parsedData.data);
  //           }
  //         });
  //       } catch (error) {
  //         console.error('Error fetching and parsing data', error);
  //       }
  //     };

  //     fetchData();
  //   }, []);

  //   const formatAirportOptions = (airports) => {
  //     return airports.map((airport) => ({
  //       value: airport.codeIataAirport,
  //       label: `${airport.nameAirport} (${airport.codeIataAirport})`,
  //     }));
  //   };

  //   const airportOptions = formatAirportOptions(airportData);
  const [seatCount, setSeatCount] = useState(0); // State for car seat count
  const [seatTypes, setSeatTypes] = useState([]); // State for individual seat types
  const [imageVisible, setImageVisible] = useState(
    Array(seatCount).fill(false)
  );

  const seatImages = {
    Infant:
      "https://www.shutterstock.com/image-photo/baby-car-seat-isolated-on-600nw-2316379347.jpg",
    Toddler:
      "https://images.maxi-cosi.ie/dorel-public-storage-prod/catalog/product/cache/655f0b719d7fda71d9d06b11a51a9714/8/1/8156106110_2024_maxicosi_carseat_toddlerchildcarseat_titansisize_grey_tonalgraphite_3qrtleft.png",
    Booster:
      "https://i5.walmartimages.com/seo/Baby-Trend-PROtect-Folding-High-Back-Booster-Car-Seat-Mars-Red-Red_16d42de1-69de-4dca-91e0-6a2b3a5e311d_1.2901e3a6803bdceaf1d871ab533cb37b.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF",
  };
  useEffect(() => {
    setImageVisible(Array(seatCount).fill(false));
  }, [seatCount]);
  // const handleImageClick = (index) => {
  //     const newImageVisibility = [...imageVisible];
  //     newImageVisibility[index] = !newImageVisibility[index]; // Toggle visibility
  //     setImageVisible(newImageVisibility);
  // };
  const handleImageClick = (index, type) => {
    setSelectedImage(seatImages[type]);
    setModalVisible(true); // Open the modal to display the image
  };
  const handleMenuClick = (index, value) => {
    const newSeatTypes = [...seatTypes];
    newSeatTypes[index] = value;
    setSeatTypes(newSeatTypes);
  };
  const handleCounterChange = (field, value) => {
    // Dispatch the update for the specified field and value
    dispatch(updateReservation({ field, value }));

    // Only adjust seat count and seat types if the field is 'carSeatCount'
    if (field === "carSeat") {
      setSeatCount(value);

      // Adjust seatTypes array to match the seat count
      const updatedSeatTypes = [...seatTypes];
      while (updatedSeatTypes.length < value) {
        updatedSeatTypes.push("Infant"); // Default for new entries
      }
      while (updatedSeatTypes.length > value) {
        updatedSeatTypes.pop();
      }
      setSeatTypes(updatedSeatTypes);

      // Dispatch the updated seat types to reservation update if it's for car seats
      dispatch(
        updateReservation({ field: "carSeatTypes", value: updatedSeatTypes })
      );
    }
  };

  const handleSeatTypeChange = (index, newType) => {
    const updatedSeatTypes = [...seatTypes];
    updatedSeatTypes[index] = newType;
    setSeatTypes(updatedSeatTypes);

    // Dispatch the updated seat types to your reservation update
    dispatch(
      updateReservation({ field: "carSeatTypes", value: updatedSeatTypes })
    );
  };
  // Automatically call pickup or drop-off change functions based on orderType
  useEffect(() => {
    if (reservation?.orderType === "Airport Pickup") {
      handleAirportPickupChange();
    } else if (reservation?.orderType === "Airport Dropoff") {
      handleAirportDropOffChange();
    } else {
      setShowFlightDetails(false);
      setShowFlightDetails2(false);
    }
  }, [reservation?.orderType]);

  return (
    <>
      <div className="container-fluid my-3">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10">
            <form>
              {/* Trip Type and Order Type Fields */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="tripType">Trip Type</label>
                  <AntdSelect
                    // placeholder="Select Trip Type"
                    placeholder={<span className="custom-select-placeholder">Select Trip Type</span>}
                    className={`customSelect w-full h-9 bg-[#faf9f9] ${selectClass}`}
                    onChange={handleTripTypeChange}
                    dropdownClassName="custom-dropdown "
                    // bordered={false}
                    defaultValue={reservation.tripType || undefined}
                  >
                    <AntdSelect.Option value="One Way">
                      <span className="text-black">One Way</span>
                    </AntdSelect.Option>
                    <AntdSelect.Option value="Hourly">
                      <span className="text-black">Hourly</span>
                    </AntdSelect.Option>
                    {/* <AntdSelect.Option value="Round Trip">
                      <span className="text-black">Round Trip</span>
                    </AntdSelect.Option> */}
                  </AntdSelect>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="orderType">Order Type</label>
                  {/* <select
                    id="orderType"
                    name="orderType"
                    value={orderType}
                    onChange={handleOrderTypeChange}
                    className={`form-control ${selectClass}`}
                  >
                    <option value="">Select Order Type</option>
                    <option value="Airport Pickup">Airport Pickup</option>
                    <option value="Airport Dropoff">Airport Dropoff</option>
                    <option value="City Tour">City Tour</option>
                    <option value="Hotel">Hotel</option>
                  </select> */}
                  <AntdSelect
                    // placeholder="Select Order Type"
                    placeholder={<span className="custom-select-placeholder">Select Order Type</span>}
                    className={`customSelect w-full h-9 bg-[#faf9f9] ${selectClass}`}
                    onChange={handleOrderTypeChange}
                    dropdownClassName="custom-dropdown"
                    defaultValue={reservation.orderType || undefined}
                  >
                    {orderTypeOption.map((option) => (
                      <AntdSelect.Option key={option.value} value={option.value}>
                        <span className="text-black">{option.label}</span>
                      </AntdSelect.Option>
                    ))}
                  </AntdSelect>

                  {errors?.orderType && (
                    <div className="mt-1">
                      <ValidateMsg error={errors.orderType} />
                    </div>
                  )}
                </div>

                {/* {tripType === "Hourly" && (
                  <div className="col-md-6 mb-3">
                    <label htmlFor="duration">Duration</label>
                    <select
                      id="duration"
                      name="duration"
                      onChange={handleDurationChange}
                      className="form-control"
                    >
                      {[...Array(24)].map((_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1} Hour{i + 1 > 1 ? "s" : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                )} */}
                {reservation?.tripType === "Hourly" && (
                  <div className="col-md-6 mb-3">
                    <label htmlFor="duration">Duration</label>
                    <AntdSelect
                      id="duration"
                      name="duration"
                      defaultValue={reservation.totalTripDuration || undefined}
                      onChange={handleDurationChange}
                      className="customSelect w-full h-9 bg-[#faf9f9]"
                      placeholder={<span className="custom-select-placeholder">Select Duration</span>}
                      dropdownClassName="custom-dropdown"
                    >
                      {[...Array(24)].map((_, i) => (
                        <AntdSelect.Option key={i + 1} value={i + 1}>
                          
                          <span className="text-black">{i + 1} Hour{i + 1 > 1 ? "s" : ""}</span>
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </div>
                )}
              </div>

              {/* Pickup Date and Time Fields */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="pickupDate" className="font-weight-bold">
                      Pickup Date
                    </label>
                    <DatePicker
                      format={"MM/DD/YYYY"}
                      onChange={handleDateChange}
                      className={`form-control ${dateandtime}`}
                      dropdownClassName="custom-date-picker-dropdown"
                      defaultValue={
                        reservation?.pickupDate
                          ? dayjs(reservation.pickupDate, "MM/DD/YYYY")
                          : null
                      }
                    />
                    <ValidateMsg error={errors?.pickupDate} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="pickupTime" className="font-weight-bold">
                      Pickup Time
                    </label>
                    <TimePicker
                      className={`form-control ${dateandtime}`}
                      use12Hours
                      format={"hh:mm A"}
                      onChange={handleTimeChange}
                      defaultValue={
                        reservation?.pickupTime
                          ? dayjs(reservation.pickupTime, "HH:mm")
                          : null
                      }
                    />
                    <ValidateMsg error={errors?.pickupTime} />
                  </div>
                </div>
              </div>

              {/* Placeholder for Additional Fields */}
              <div className="row">
                <div className="col-12">
                  <label className="form-label"><strong>Pick Up</strong></label>
                  {/* Additional fields will go here */}
                </div>
              </div>


              {/* <div className="form-row">
                <div className="form-column">
                  <input type="radio" id="radio1" name="pickup" value="airport" checked={reservation?.orderType === "Airport Pickup"} onClick={handleAirportPickupChange} />
                  &nbsp;
                  <label style={{ fontWeight: 'normal' }} htmlFor="radio1">Airport</label>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <input type="radio" id="radio2" name="pickup" value="address" checked={reservation?.orderType !== "Airport Pickup"} onClick={handlePickupAddressChange} />
                  &nbsp;
                  <label style={{ fontWeight: 'normal' }} htmlFor="radio2">Address</label>
                  {pickupType === 'airport' && (
                    <>
                      <span className="hide-new-details" onClick={toggleFlightDetails}>
                        {showFlightDetails ? 'Hide Flight Details' : 'Show Flight Details'}
                      </span>
                      <img
                        src={Eye}
                        alt="eye icon"
                        className="eye-icon"
                        style={{ width: '24px', height: '24px' }}
                        onClick={toggleFlightDetails}
                      />
                    </>
                  )}
                </div>
              </div> */}

              <div className="form-row">
                <div className="form-column">
                  <MapAddress
                    name="pickupAddress"
                    value={pickupAddress.address || reservation?.pickupAddress.address || ""}
                    getFullDetails={(details) => {

                      handleAddressChange("pickupAddress", {
                        ...pickupAddress,
                        ...details,
                      })
                      console.log("Location", {
                        ...pickupAddress,
                        ...details,
                      })
                    }
                    }
                    onChange={(selectedValue) => {
                      handleAddressChange("pickupAddress", {
                        ...pickupAddress,
                        address: selectedValue,
                      });
                    }}
                    onIconClick={() => setOpenInput(!openInput)}
                  >
                    {reservation?.orderType == "Airport Pickup" ? (
                      <Icon
                        icon="mdi:airplane"
                        style={{
                          font: "24px",
                          color: openInput ? "#058CBD" : "gray",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </MapAddress>
                  <ValidateMsg error={errors?.pickupAddress} />
                </div>
              </div>
              <br />
              {showFlightDetails && openInput && (
                <div className="form-row mb-4">
                  <div className="form-column">
                    <label htmlFor="flight" style={{ width: '8ch' }}>Flight</label>
                    {/* <input className="inputfield form-control" type="text" id="flight" name="flight" value={flight} onChange={handleFlightChange} placeholder="#5487" /> */}
                    <input
                      className="inputfield form-control"
                      type="text"
                      id="flight"
                      name="flight"
                      value={reservation.pickupAddress?.airportInfo?.flightNumber}
                      // onChange={handleFlightChange}
                      onChange={(e) => {
                        handleChange('pickupAddress', {
                          ...reservation.pickupAddress,
                          airportInfo: {
                            ...reservation.pickupAddress.airportInfo,
                            flightNumber: e.target.value,
                          },
                        });
                      }}
                      placeholder="Enter Flight"
                    />
                  </div>
                  <div className="form-column">
                    <label htmlFor="airline" style={{ width: '8ch' }}>Airline</label>
                    {/* <input style={{width: '90%'}} className="inputfield form-control" type="text" id="airline" name="airline" value={airline} onChange={handleAirlineChange} placeholder="Turkish" /> */}
                    <Select
                      onChange={(option) => {
                        handleChange('pickupAddress', {
                          ...reservation.pickupAddress,
                          airportInfo: {
                            ...reservation.pickupAddress.airportInfo,
                            airline: option.value,
                          },
                        });
                      }}
                      value={airlineOptions.find((option) => option.value === reservation.pickupAddress?.airportInfo?.airline)}
                      placeholder="Enter Airline"
                      options={airlineOptions}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                      isSearchable={true}

                      styles={{
                        control: (base) => ({
                          ...base,
                          padding: '2px',
                          border: '1px solid #ccc',
                          backgroundColor: '#faf9f9',
                          borderRadius: '4px',
                          height: '40px',
                          width: '100%'
                        }),
                        menu: (base) => ({
                          ...base,
                          width: '100%',
                          minWidth: '100%',
                        }),
                      }}
                    />
                  </div>
                </div>

              )}
              {/* <br />
                <br /> */}
              <div className="form-row">
                <label className="form-label">Drop Off</label>
              </div>

              {/* <div className="form-row">
                <div className="form-column">
                  <input type="radio" id="radio3" name="dropoff" value="airport" checked={reservation?.orderType === "Airport Dropoff"} onClick={handleAirportDropOffChange} />
                  &nbsp;
                  <label style={{ fontWeight: 'normal' }} htmlFor="radio3">Airport</label>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <input type="radio" id="radio4" name="dropoff" value="address" checked={reservation?.orderType !== "Airport Dropoff"} onClick={handleDropOffAddressChange} />
                  &nbsp;
                  <label style={{ fontWeight: 'normal' }} htmlFor="radio4">Address</label>
                  {dropoffType === 'airport' && (
                    <>
                      <span className="hide-new-details" onClick={toggleFlightDetails2}>
                        {showFlightDetails2 ? 'Hide Flight Details' : 'Show Flight Details'}
                      </span>
                      <img
                        src={Eye}
                        alt="eye icon"
                        className="eye-icon"
                        style={{ width: '24px', height: '24px' }}
                        onClick={toggleFlightDetails2}
                      />
                    </>
                  )}
                </div>
              </div> */}

              <div className="form-row">
                <div className="form-column">
                  <MapAddress
                    name="dropoffAddress"
                    value={dropoffAddress.address || reservation?.dropoffAddress.address || ""}
                    getFullDetails={(details) => {

                      handleDropoffChange("dropoffAddress", {
                        ...dropoffAddress,
                        ...details,
                      })
                      console.log("Location", {
                        ...dropoffAddress,
                        ...details,
                      })
                    }
                    }
                    onChange={(selectedValue) => {
                      handleDropoffChange("dropoffAddress", {
                        ...dropoffAddress,
                        address: selectedValue,
                      });
                    }}
                    onIconClick={() => setOpenInput2(!openInput2)}
                  >
                    {reservation?.orderType == "Airport Dropoff" ? (
                      <Icon
                        icon="mdi:airplane"
                        style={{
                          font: "24px",
                          color: openInput2 ? "#058CBD" : "gray",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </MapAddress>
                  <ValidateMsg error={errors?.dropoffAddress} />
                </div>
              </div>
              <br />
              {showFlightDetails2 && openInput2 && (

                <div className="form-row mb-2">
                  <div className="form-column">
                    <label htmlFor="flight2" style={{ width: '8ch' }}>Flight</label>
                    {/* <input className="inputfield form-control" type="text" id="flight2" name="flight2" value={flightDropoff} onChange={handleFlightDropoffChange} placeholder="#5487" /> */}
                    <input
                      className="inputfield form-control"
                      type="text"
                      id="flight"
                      name="flight"
                      value={reservation.dropoffAddress?.airportInfo?.flightNumber}
                      // onChange={handleFlightChange}
                      onChange={(e) => {
                        handleChange('dropoffAddress', {
                          ...reservation.dropoffAddress,
                          airportInfo: {
                            ...reservation.dropoffAddress.airportInfo,
                            flightNumber: e.target.value,
                          },
                        });
                      }}
                      placeholder="Enter Flight"
                    />
                  </div>
                  <div className="form-column">
                    <label htmlFor="airline2" style={{ width: '8ch' }}>Airline</label>
                    {/* <input style={{width: '90%'}} className="inputfield form-control" type="text" id="airline2" name="airline2" value={airlineDropoff} onChange={handleDropOffAirlineChange} placeholder="Turkish" /> */}
                    <Select
                      onChange={(option) => {
                        handleChange('dropoffAddress', {
                          ...reservation.dropoffAddress,
                          airportInfo: {
                            ...reservation.dropoffAddress.airportInfo,
                            airline: option.value,
                          },
                        });
                      }}
                      value={airlineOptions.find((option) => option.value === reservation.dropoffAddress?.airportInfo?.airline)}
                      placeholder="Enter Airline"
                      options={airlineOptions}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                      isSearchable={true}

                      styles={{
                        control: (base) => ({
                          ...base,
                          padding: '2px',
                          border: '1px solid #ccc',
                          backgroundColor: '#faf9f9',
                          borderRadius: '4px',
                          height: '40px',
                          width: '100%'
                        }),
                        menu: (base) => ({
                          ...base,
                          width: '100%',
                          minWidth: '100%',
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
              {/* <br /> */}
              <div className="form-row" style={{ display: 'flex', gap: '20px' }}>
                {/* Passenger Field */}
                <div className="form-column" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <label htmlFor="passenger" style={{ width: '8ch', textAlign: 'center' }}>Passenger</label>
                  <Counter
                    onCounterChange={(value) => handleCounterChange("passenger", value)}
                    text={Number(passengercount) ? Number(passengercount) : reservation?.passenger || 0}
                  />
                  <ValidateMsg error={errors?.passenger} />
                </div>

                {/* Luggage Field */}
                <div className="form-column" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <label htmlFor="luggage" style={{ width: '8ch', textAlign: 'center' }}>Luggage</label>
                  <Counter
                    onCounterChange={(value) => handleCounterChange("lauggage", value)}
                    text={Number(luggagecount) ? Number(luggagecount) : reservation?.lauggage || 0}
                  />
                </div>

                {/* Car Seat Field */}
                <div className="form-column" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <label htmlFor="carseat" style={{ width: '8ch', textAlign: 'center' }}>Car Seat</label>
                  <Counter
                    onCounterChange={(value) => handleCounterChange("carSeat", value)}
                    text={Number(seatCount) ? Number(seatCount) : reservation?.carSeat || 0}
                  />
                </div>
              </div>

              {/* Dynamic Car Seat Type Fields */}
              {/* {Array.from({ length: seatCount }).map((_, index) => (
    <div className="form-column" style={{ marginTop: '10px', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={index}>
        <label htmlFor={`carseat-type-${index}`} style={{ display: 'block', marginBottom: '5px', textAlign: 'center' }}>
            Car Seat Type {index + 1}
        </label>
        <select
            className="selectfield"
            id={`carseat-type-${index}`}
            name={`seattype-${index}`}
            value={seatTypes[index] || "Infant"}
            onChange={(e) => handleSeatTypeChange(index, e.target.value)}
            style={{ width: '100%' }} // Ensures select field spans full width
        >
            <option value="Infant">Infant (ages 0-1)</option>
            <option value="Toddler">Toddler Seat (ages 1-2)</option>
            <option value="Booster">Booster Seat (ages 3-6)</option>
        </select>
    </div>
))} */}
              <div
                className="seat-dropdowns-wrapper mt-4"
                style={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                {Array.from({ length: seatCount != 0 ? seatCount : reservation?.carSeat }).map((_, index) => (
                  <div key={`seat-${index}`} style={{ width: "100%" }}>
                    <label
                      htmlFor={`car-seat-${index}`}
                      style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
                    >
                      Car Seat Type {index + 1}
                    </label>

                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="Infant"
                            onClick={() => handleSeatTypeChange(index, "Infant")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Infant (ages 0-1)</span>
                              <button
                                className="btn btn-info btn-sm"
                                onClick={() => handleImageClick(index, "Infant")}
                              >
                                View Image
                              </button>
                            </div>
                          </Menu.Item>
                          <Menu.Item
                            key="Toddler"
                            onClick={() => handleSeatTypeChange(index, "Toddler")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Toddler Seat (ages 1-2)</span>
                              <button
                                className="btn btn-info btn-sm"
                                onClick={() => handleImageClick(index, "Toddler")}
                              >
                                View Image
                              </button>
                            </div>
                          </Menu.Item>
                          <Menu.Item
                            key="Booster"
                            onClick={() => handleSeatTypeChange(index, "Booster")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Booster Seat (ages 3-6)</span>
                              <button
                                className="btn btn-info btn-sm"
                                onClick={() => handleImageClick(index, 'Booster')}
                              >
                                View Image
                              </button>
                            </div>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        style={{
                          textAlign: "left",
                          padding: "8px 12px",
                          width: "100%", // Ensures dropdown spans full width
                        }}
                      >
                        {seatTypes[index] || reservation?.carSeatTypes[index] || "Select Seat Type"}
                      </button>
                    </Dropdown>
                  </div>
                ))}
              </div>
              {/* Image Modal */}
              <Modal
                title="Car Seat Image"
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={null}
              >
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Seat Type"
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                )}
              </Modal>

              {/* <div className="form-row">
                    <div className="form-column">
                        <label htmlFor="passenger" style={{ width: '8ch' }}>Passenger</label>
                            <Counter
                                onCounterChange={(value) =>
                                    handleCounterChange("passenger", value)
                                }
                                text={Number(passengercount) ? Number(passengercount) : 0}
                            />
                            <ValidateMsg error={errors?.passenger}/>
                    </div>
                    <div className="form-column">
                        <label htmlFor="luggage" style={{ width: '8ch' }}>Luggage</label>
                        <Counter
                                onCounterChange={(value) =>
                                    handleCounterChange("lauggage", value)
                                }
                                text={Number(luggagecount) ? Number(luggagecount) : 0}
                            />
                    </div> */}
              {/* <div class="form-column">
                        <label htmlFor="carseat" style={{ width: '8ch' }}>Car Seat</label>
                        <Counter
                                onCounterChange={(value) =>
                                    handleCounterChange("carSeat", value)
                                }
                                text={Number(seatcount) ? Number(seatcount) : 0}
                            />
                    </div>
                    <div className="form-column">
                    <label htmlFor="airline">Car Seat</label>
                    <select className="selectfield" id="airline" name="seattype" >
                        <option value="Infant">Infant (ages 0-1)</option>
                        <option value="Toddler">Toddler Seat (ages 1-2)</option>
                        <option value="Booster">Booster Seat (ages 3-6)</option>
                    </select>
                    </div> */}
              {/* <div className="form-column">
                <label htmlFor="carseat" style={{ width: '8ch' }}>Car Seat</label>
                <Counter
                    onCounterChange={(value) => handleCounterChange("carSeat", value)}
                    text={Number(seatCount) ? Number(seatCount) : 0}
                />
            </div>
            </div>
            {Array.from({ length: seatCount }).map((_, index) => (
                <div className="form-column" style={{ marginTop: '10px' }} key={index}>
                    <label htmlFor={`carseat-type-${index}`} style={{ display: 'block', marginBottom: '5px' }}>
                        Car Seat Type {index + 1}
                    </label>
                    <select
                        className="selectfield"
                        id={`carseat-type-${index}`}
                        name={`seattype-${index}`}
                        value={seatTypes[index] || "Infant"}
                        onChange={(e) => handleSeatTypeChange(index, e.target.value)}
                        style={{ width: '100%' }} // Ensures select field spans full width
                    >
                        <option value="Infant">Infant (ages 0-1)</option>
                        <option value="Toddler">Toddler Seat (ages 1-2)</option>
                        <option value="Booster">Booster Seat (ages 3-6)</option>
                    </select>
                </div>
            ))} */}

              {/* if trip type round trip show this */}
              {tripType === "Round Trip" && (
                <>
                  <br />
                  <div className="d-flex mt-2 mb-2">
                    <span
                      style={{ fontSize: "24px", fontWeight: "600" }}
                      className="me-2"
                    >
                      Round Trip:
                    </span>
                    <span style={{ fontSize: "24px" }}> Return</span>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          style={{ marginBottom: "5px", fontWeight: "600" }}
                          htmlFor="pickupDate"
                        >
                          Pickup Date
                        </label>
                        <DatePicker
                          format={"MM/DD/YYYY"}
                          onChange={(date) => {
                            if (date) {
                              const formattedDate =
                                dayjs(date).format("MM/DD/YYYY");
                              console.log("Formatted date:", formattedDate);
                              // setPickupDate(formattedDate);
                              handleChange2("pickupDate", formattedDate);
                            } else {
                              // setPickupDate(null);
                              handleChange2("pickupDate", null);
                            }
                          }}
                          dropdownClassName="custom-date-picker-dropdown"
                          className="form-control DateTime2"
                        // style={{
                        //     width: '87%',
                        //     padding: '8px',
                        //     border: '1px solid ',
                        //     borderColor: '#D0D5DD',
                        //     borderRadius: '4px'
                        // }}
                        />
                        <ValidateMsg error={errors2?.pickupDate} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          style={{ marginBottom: "5px", fontWeight: "600" }}
                          htmlFor="pickupTime"
                        >
                          Pickup Time
                        </label>
                        <TimePicker
                          use12Hours
                          format={"hh:mm A"}
                          className="form-control DateTime2"
                          onChange={(time) => {
                            if (time) {
                              const formattedTime =
                                dayjs(time).format("hh:mm A");
                              console.log("Formatted time:", formattedTime);
                              // setPickupTime(formattedTime);
                              handleChange2("pickupTime", formattedTime);
                            } else {
                              // setPickupTime(null);
                              handleChange2("pickupTime", null);
                            }
                          }}
                        // style={{
                        //     width: '87%',
                        //     padding: '8px',
                        //     border: '1px solid ',
                        //     borderColor: '#D0D5DD',
                        //     borderRadius: '4px'
                        // }}
                        />
                        <ValidateMsg error={errors2?.pickupTime} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="form-row">
                    <label className="form-label">Pick Up</label>
                  </div>

                  {/* <div className="form-row">
                    <div className="form-column">
                      <input
                        type="radio"
                        id="radio5"
                        name="RTpickup"
                        value="airport"
                        checked={reservation?.orderType === "Airport Pickup"}
                        onClick={handleRTAirportPickupChange}
                      />
                      &nbsp;
                      <label style={{ fontWeight: "normal" }} htmlFor="radio5">
                        Airport
                      </label>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <input
                        type="radio"
                        id="radio6"
                        name="RTpickup"
                        value="address"
                        checked={reservation?.orderType !== "Airport Pickup"}
                        onClick={handleRTAddressPickupChange}
                      />
                      &nbsp;
                      <label style={{ fontWeight: "normal" }} htmlFor="radio6">
                        Address
                      </label>
                      {pickupTypeRT === "airport" && (
                        <>
                          <span
                            className="hide-new-details"
                            onClick={toggleFlightDetails3}
                          >
                            {showFlightDetails3
                              ? "Hide Flight Details"
                              : "Show Flight Details"}
                          </span>
                          <img
                            src={Eye}
                            alt="eye icon"
                            className="eye-icon"
                            style={{ width: "24px", height: "24px" }}
                            onClick={toggleFlightDetails3}
                          />
                        </>
                      )}
                    </div>
                  </div> */}

                  <div className="form-row">
                    <div className="form-column">
                      <MapAddress
                        name="pickupAddress"
                        value={pickupAddressRT.address || ""}
                        getFullDetails={(details) => {
                          handleRTAddressChange("pickupAddress", {
                            ...pickupAddressRT,
                            ...details,
                          });
                          console.log("Location", {
                            ...pickupAddressRT,
                            ...details,
                          });
                        }}
                        onChange={(selectedValue) => {
                          handleRTAddressChange("pickupAddress", {
                            ...pickupAddressRT,
                            address: selectedValue,
                          });
                        }}
                        onIconClick={() => setOpenInput3(!openInput3)}
                      >
                        {reservation?.orderType == "Airport Dropoff" ? (
                          <Icon
                            icon="mdi:airplane"
                            style={{
                              font: "24px",
                              color: openInput3 ? "#058CBD" : "gray",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </MapAddress>
                      <ValidateMsg error={errors2?.pickupAddress} />
                    </div>
                  </div>
                  <br />
                  {openInput3 && (
                    <div className="form-row mb-4">
                      <div className="form-column">
                        <label
                          htmlFor="rt-pickup-flight"
                          style={{ width: "8ch" }}
                        >
                          Flight
                        </label>
                        {/* <input className="inputfield form-control" type="text" id="rt-pickup-flight" name="flight" value={flightRT} onChange={handleRTFlightChange} placeholder="#5487" /> */}
                        <input
                          className="inputfield form-control"
                          type="text"
                          id="rt-pickup-flight"
                          name="flight"
                          value={
                            roundtrip.pickupAddress?.airportInfo?.flightNumber
                          }
                          // onChange={handleFlightChange}
                          onChange={(e) => {
                            handleChange2("pickupAddress", {
                              ...roundtrip.pickupAddress,
                              airportInfo: {
                                ...roundtrip.pickupAddress.airportInfo,
                                flightNumber: e.target.value,
                              },
                            });
                          }}
                          placeholder="Enter Flight"
                        />
                      </div>
                      <div className="form-column">
                        <label
                          htmlFor="rt-pickup-airline"
                          style={{ width: "8ch" }}
                        >
                          Airline
                        </label>
                        {/* <input style={{width: '90%'}} className="inputfield form-control" type="text" id="rt-pickup-airline" name="airline" value={airlineRT} onChange={handleRTAirlineChange} placeholder="Turkish" /> */}
                        <Select
                          onChange={(option) => {
                            handleChange2("pickupAddress", {
                              ...roundtrip.pickupAddress,
                              airportInfo: {
                                ...roundtrip.pickupAddress.airportInfo,
                                airline: option.value,
                              },
                            });
                          }}
                          value={airlineOptions.find(
                            (option) =>
                              option.value ===
                              roundtrip.pickupAddress?.airportInfo?.airline
                          )}
                          placeholder="Enter Airline"
                          options={airlineOptions}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          isSearchable={true}
                          styles={{
                            control: (base) => ({
                              ...base,
                              padding: "2px",
                              border: "1px solid #ccc",
                              backgroundColor: "#faf9f9",
                              borderRadius: "4px",
                              height: "40px",
                              width: "100%",
                            }),
                            menu: (base) => ({
                              ...base,
                              width: "100%",
                              minWidth: "100%",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/* <br />
                <br /> */}
                  <div className="form-row">
                    <label className="form-label">Drop Off</label>
                  </div>

                  {/* <div className="form-row">
                    <div className="form-column">
                      <input
                        type="radio"
                        id="radio7"
                        name="dropoff"
                        value="airport"
                        checked={reservation?.orderType === "Airport Pickup"}
                        onClick={handleRTAirportDropOffChange}
                      />
                      &nbsp;
                      <label style={{ fontWeight: "normal" }} htmlFor="radio7">
                        Airport
                      </label>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <input
                        type="radio"
                        id="radio8"
                        name="dropoff"
                        value="address"
                        checked={reservation?.orderType !== "Airport Pickup"}
                        onClick={handleRTAddressDropOffChange}
                      />
                      &nbsp;
                      <label style={{ fontWeight: "normal" }} htmlFor="radio8">
                        Address
                      </label>
                      {dropoffTypeRT === "airport" && (
                        <>
                          <span
                            className="hide-new-details"
                            onClick={toggleFlightDetails4}
                          >
                            {showFlightDetails4
                              ? "Hide Flight Details"
                              : "Show Flight Details"}
                          </span>
                          <img
                            src={Eye}
                            alt="eye icon"
                            className="eye-icon"
                            style={{ width: "24px", height: "24px" }}
                            onClick={toggleFlightDetails4}
                          />
                        </>
                      )}
                    </div>
                  </div> */}

                  <div className="form-row">
                    <div className="form-column">
                      <MapAddress
                        name="dropoffAddress"
                        value={dropoffAddressRT.address || ""}
                        getFullDetails={(details) => {
                          handleRTDropoffChange("dropoffAddress", {
                            ...dropoffAddressRT,
                            ...details,
                          });
                          console.log("Location", {
                            ...dropoffAddressRT,
                            ...details,
                          });
                        }}
                        onChange={(selectedValue) => {
                          handleRTDropoffChange("dropoffAddress", {
                            ...dropoffAddressRT,
                            address: selectedValue,
                          });
                        }}
                        onIconClick={() => setOpenInput4(!openInput4)}
                      >
                        {reservation?.orderType == "Airport Pickup" ? (
                          <Icon
                            icon="mdi:airplane"
                            style={{
                              font: "24px",
                              color: openInput4 ? "#058CBD" : "gray",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </MapAddress>
                      <ValidateMsg error={errors2?.dropoffAddress} />
                    </div>
                  </div>
                  <br />
                  {openInput4 && (
                    <div className="form-row">
                      <div className="form-column">
                        <label htmlFor="rt-flight" style={{ width: "8ch" }}>
                          Flight
                        </label>
                        {/* <input className="inputfield form-control" type="text" id="rt-flight" name="flight2" value={flightDropoffRT} onChange={handleRTFlightDropoffChange} placeholder="#5487" /> */}
                        <input
                          className="inputfield form-control"
                          type="text"
                          id="flight"
                          name="flight"
                          value={
                            roundtrip.dropoffAddress?.airportInfo?.flightNumber
                          }
                          onChange={(e) => {
                            handleChange2("dropoffAddress", {
                              ...roundtrip.dropoffAddress,
                              airportInfo: {
                                ...roundtrip.dropoffAddress.airportInfo,
                                flightNumber: e.target.value,
                              },
                            });
                          }}
                          placeholder="Enter Flight"
                        />
                      </div>
                      <div className="form-column">
                        <label htmlFor="rt-airline" style={{ width: "8ch" }}>
                          Airline
                        </label>
                        {/* <input style={{width: '90%'}} className="inputfield form-control" type="text" id="rt-airline" name="airline2" value={airlineDropoffRT} onChange={handleRTDropOffAirlineChange} placeholder="Turkish" /> */}
                        <Select
                          onChange={(option) => {
                            handleChange2("dropoffAddress", {
                              ...roundtrip.dropoffAddress,
                              airportInfo: {
                                ...roundtrip.dropoffAddress.airportInfo,
                                airline: option.value,
                              },
                            });
                          }}
                          value={airlineOptions.find(
                            (option) =>
                              option.value ===
                              roundtrip.dropoffAddress?.airportInfo?.airline
                          )}
                          placeholder="Enter Airline"
                          options={airlineOptions}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          isSearchable={true}
                          styles={{
                            control: (base) => ({
                              ...base,
                              padding: "2px",
                              border: "1px solid #ccc",
                              backgroundColor: "#faf9f9",
                              borderRadius: "4px",
                              height: "40px",
                              width: "100%",
                            }),
                            menu: (base) => ({
                              ...base,
                              width: "100%",
                              minWidth: "100%",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  <div
                    className="form-row"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    {/* Passenger Field */}
                    <div
                      className="form-column"
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <label
                        htmlFor="passenger"
                        style={{ width: "8ch", textAlign: "center" }}
                      >
                        Passenger
                      </label>
                      <Counter
                        onCounterChange={(value) =>
                          handleCounterChange2("passenger", value)
                        }
                        text={
                          Number(passengercount2) ? Number(passengercount2) : 0
                        }
                      />
                      <ValidateMsg error={errors2?.passenger} />
                    </div>

                    {/* Luggage Field */}
                    <div
                      className="form-column"
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <label
                        htmlFor="luggage"
                        style={{ width: "8ch", textAlign: "center" }}
                      >
                        Luggage
                      </label>
                      <Counter
                        onCounterChange={(value) =>
                          handleCounterChange2("lauggage", value)
                        }
                        text={Number(luggagecount2) ? Number(luggagecount2) : 0}
                      />
                    </div>

                    {/* Car Seat Field */}
                    <div
                      className="form-column"
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <label
                        htmlFor="carseat"
                        style={{ width: "8ch", textAlign: "center" }}
                      >
                        Car Seat
                      </label>
                      <Counter
                        onCounterChange={(value) =>
                          handleCounterChange("carSeat", value)
                        }
                        text={Number(seatCount) ? Number(seatCount) : 0}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    {/* <div className="form-column">
                        <label htmlFor="passenger" style={{ width: '8ch' }}>Passenger</label>
                            <Counter
                                onCounterChange={(value) =>
                                    handleCounterChange2("passenger", value)
                                }
                                text={Number(passengercount2) ? Number(passengercount2) : 0}
                            />
                            <ValidateMsg error={errors2?.passenger}/>
                    </div>
                    <div className="form-column">
                        <label htmlFor="luggage" style={{ width: '8ch' }}>Luggage</label>
                        <Counter
                                onCounterChange={(value) =>
                                    handleCounterChange2("lauggage", value)
                                }
                                text={Number(luggagecount2) ? Number(luggagecount2) : 0}
                            />
                    </div> */}
                    {/* <div class="form-column">
                        <label htmlFor="carseat" style={{ width: '8ch' }}>Car Seat</label>
                        <Counter
                                onCounterChange={(value) =>
                                    handleCounterChange2("carSeat", value)
                                }
                                text={Number(seatcount2) ? Number(seatcount2) : 0}
                            />
                    </div>
                    <div className="form-column">
                    <label htmlFor="car-seat">Car Seat</label>
                    <select className="selectfield" id="car-seat" name="seattype" >
                        <option value="Infant">Infant (ages 0-1)</option>
                        <option value="Toddler">Toddler Seat (ages 1-2)</option>
                        <option value="Booster">Booster Seat (ages 3-6)</option>
                    </select>
                    </div> */}
                    {/* <div className="form-column">
                <label htmlFor="carseat" style={{ width: '8ch' }}>Car Seat</label>
                <Counter
                    onCounterChange={(value) => handleCounterChange("carSeat", value)}
                    text={Number(seatCount) ? Number(seatCount) : 0}
                />
            </div> */}

                    {/* {Array.from({ length: seatCount }).map((_, index) => (
    <div className="mb-3" key={index}>
        <label htmlFor={`carseat-type-${index}`} className="form-label">
            Car Seat Type {index + 1}
        </label>
        <select
            className="form-select car-seat-select"
            id={`carseat-type-${index}`}
            name={`seattype-${index}`}
            value={seatTypes[index] || "Infant"}
            onChange={(e) => handleSeatTypeChange(index, e.target.value)}
            style={{
                height: '40px', // Adjust height here
                border: '1px solid rgb(204, 204, 204)', // Border color
                color: 'rgb(204, 204, 204)', // Text color
            }}
        >
            <option value="Infant">Infant (ages 0-1)</option>
            <option value="Toddler">Toddler Seat (ages 1-2)</option>
            <option value="Booster">Booster Seat (ages 3-6)</option>
        </select>
    </div>
))} */}

                    {/* {Array.from({ length: seatCount }).map((_, index) => (
        <div key={`seat-${index}`}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="Infant">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Infant (ages 0-1)</span>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => handleImageClick(index, 'Infant')}
                    >
                      View Image
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item key="Toddler">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Toddler Seat (ages 1-2)</span>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => handleImageClick(index, 'Toddler')}
                    >
                      View Image
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item key="Booster">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Booster Seat (ages 3-6)</span>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => handleImageClick(index, 'Booster')}
                    >
                      View Image
                    </button>
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <button type="button" className="btn btn-secondary dropdown-toggle">
              {seatTypes[index] || 'Select Seat Type'}
            </button>
          </Dropdown>
        </div>
      ))} */}
                    {/* <div className="seat-dropdowns-wrapper">
{Array.from({ length: seatCount }).map((_, index) => (
        <div key={`seat-${index}`} style={{ marginBottom: '16px' }}>
             <label htmlFor={`car-seat-${index}`} style={{ fontWeight: 'bold', display: 'block' }}>
        Car Seat Type {index + 1}
      </label>

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="Infant" onClick={() => handleMenuClick(index, 'Infant')}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Infant (ages 0-1)</span>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => handleImageClick(index, 'Infant')}
                    >
                      View Image
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item key="Toddler" onClick={() => handleMenuClick(index, 'Toddler')}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Toddler Seat (ages 1-2)</span>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => handleImageClick(index, 'Toddler')}
                    >
                      View Image
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item key="Booster" onClick={() => handleMenuClick(index, 'Booster')}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Booster Seat (ages 3-6)</span>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => handleImageClick(index, 'Booster')}
                    >
                      View Image
                    </button>
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle dropdown-button"
              style={{
                // width: '100%',
                textAlign: 'left',
                padding: '8px 12px',
              }}
            >
              {seatTypes[index] || 'Select Seat Type'}
            </button>
          </Dropdown>
        </div>
      ))} */}
                    <div
                      className="seat-dropdowns-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      {Array.from({ length: seatCount }).map((_, index) => (
                        <div key={`seat-${index}`} style={{ width: "100%" }}>
                          <label
                            htmlFor={`car-seat-${index}`}
                            style={{
                              fontWeight: "bold",
                              display: "block",
                              marginBottom: "8px",
                            }}
                          >
                            Car Seat Type {index + 1}
                          </label>

                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key="Infant"
                                  onClick={() =>
                                    handleMenuClick(index, "Infant")
                                  }
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span>Infant (ages 0-1)</span>
                                    <button
                                      className="btn btn-info btn-sm"
                                      onClick={() =>
                                        handleImageClick(index, "Infant")
                                      }
                                    >
                                      View Image
                                    </button>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  key="Toddler"
                                  onClick={() =>
                                    handleMenuClick(index, "Toddler")
                                  }
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span>Toddler Seat (ages 1-2)</span>
                                    <button
                                      className="btn btn-info btn-sm"
                                      onClick={() =>
                                        handleImageClick(index, "Toddler")
                                      }
                                    >
                                      View Image
                                    </button>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  key="Booster"
                                  onClick={() =>
                                    handleMenuClick(index, "Booster")
                                  }
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span>Booster Seat (ages 3-6)</span>
                                    <button
                                      className="btn btn-info btn-sm"
                                      onClick={() =>
                                        handleImageClick(index, "Booster")
                                      }
                                    >
                                      View Image
                                    </button>
                                  </div>
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}
                          >
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle dropdown-button"
                              style={{
                                textAlign: "left",
                                padding: "8px 62px",
                              }}
                            >
                              {seatTypes[index] || "Select Seat Type"}
                            </button>
                          </Dropdown>
                        </div>
                      ))}

                      {/* {Array.from({ length: seatCount }).map((_, index) => (
    <div key={`seat-${index}`} className="seat-dropdown-container">
      <label htmlFor={`car-seat-${index}`} style={{ fontWeight: 'bold', display: 'block' }}>
        Car Seat Type {index + 1}
      </label>

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="Infant">
              <div className="d-flex justify-content-between align-items-center">
                <span>Infant (ages 0-1)</span>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => handleMenuClick(index, 'Infant')}
                >
                  View Image
                </button>
              </div>
            </Menu.Item>
            <Menu.Item key="Toddler">
              <div className="d-flex justify-content-between align-items-center">
                <span>Toddler Seat (ages 1-2)</span>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => handleMenuClick(index, 'Toddler')}
                >
                  View Image
                </button>
              </div>
            </Menu.Item>
            <Menu.Item key="Booster">
              <div className="d-flex justify-content-between align-items-center">
                <span>Booster Seat (ages 3-6)</span>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => handleMenuClick(index, 'Booster')}
                >
                  View Image
                </button>
              </div>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <button
          type="button"
          className="btn btn-secondary dropdown-toggle dropdown-button"
          style={{
            // width: '100%', // Ensures each dropdown takes full width
            textAlign: 'left', // Aligns text to the left
            padding: '8px 12px', // Adds padding for better appearance
          }}
        >
          {seatTypes[index] || 'Select Seat Type'}
        </button>
      </Dropdown>
    </div>
  ))} */}
                    </div>

                    {/* Image Modal */}
                    <Modal
                      title="Car Seat Image"
                      visible={modalVisible}
                      onCancel={handleModalClose}
                      footer={null}
                    >
                      {selectedImage && (
                        <img
                          src={selectedImage}
                          alt="Seat Type"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                    </Modal>
                  </div>
                </>
              )}
              <br />
              <div className="flex justify-center items-center lg:justify-end">
                <div className="flex justify-center lg:justify-end">
                  <button
                    className="button-submit"
                    type="button"
                    onClick={onNextClick}
                    style={{
                      backgroundColor: "rgb(5, 140, 189)",
                      borderColor: "rgb(5, 140, 189)",
                    }}
                  >
                    Next
                    {/* &nbsp; */}
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        fill="white"
                      ></path>
                    </svg> */}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormContainer;
