import { React, useEffect, useState } from "react";
import PaymentCard from "./ReusableCards/PaymentCard";
import { Elements } from "@stripe/react-stripe-js";
import clsx from "clsx";
import { loadStripe } from "@stripe/stripe-js";
import AddCard from "./AddCard";
import { Card, Modal } from "antd";
import CreditCard from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import PaymentForm from "./PaymentForm";

const Cards = ({ fetchData, company, clientInfo, clientId }) => {
  const [cardtype, setCardType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [stripepromise, setStripePromise] = useState();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [individualCards, setIndividualCards] = useState([]);
  const [corporateCards, setCorporateCards] = useState([]);
  const [stripeCusId, setStripeCusId] = useState("");
  const [stripeConnAccount, setStripeConnAccount] = useState("");
  const [paymentCards, setPaymentCards] = useState([]);
  const [cloverCards, setCloverCards] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  useEffect(() => {
    const stripeKey = process.env.REACT_APP_PUBLIC_STRIPE_KEY;
    const promise = loadStripe(stripeKey, {
      stripeAccount: stripeConnAccount,
    });
    // const promise = loadStripe(
    //   "pk_test_51OpwTTB0PwSGyFJmGKMdLeZ7KHi98fSbm7jIvDEPLMIgFyokAZx5vdgcgVFFmJ67dwPHIXmJ8HlpoRiudr1P3OMn00qqvPIKh0",
    //   { stripeAccount: stripeConnAccount }
    // );
    setStripePromise(promise);
  }, [stripeConnAccount]);

  const togglePopup = () => {
    setOpenModal(!openModal);
    setCardType("individual");
  };

  const togglePopup2 = () => {
    setOpenModal(!openModal);
    setCardType("corporate");
  };

  useEffect(() => {
    if (clientInfo?.paymentMethods) {
      setPaymentCards(clientInfo.paymentMethods);
    } else {
      setPaymentCards([]);
    }
  }, [clientInfo]);

  useEffect(() => {
    fetchData();
  }, [individualCards, corporateCards]);


  useEffect(() => {
    if (clientInfo?.stripeCusId) {
      setStripeCusId(clientInfo?.stripeCusId);
      setStripeConnAccount(clientInfo?.stripeConnAccId);
    }
  }, [clientInfo]);

  useEffect(() => {
    if (clientInfo?.cloverCards) {
      setCloverCards(clientInfo.cloverCards);
    } else {
      setCloverCards([]);
    }
  }, [clientInfo?.cloverCards]);

  const handleModalClose = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <div className="flex justify-center items-center p-4 md:p-4">
        <h1 className="text-lg md:text-xl font-semibold text-gray-800">
          Card Info
        </h1>
      </div>
      <div className="w-full flex flex-col items-start">
        {company?.selectedGateway === "Stripe" && (
          <div className="flex flex-col gap-6 w-full">
            {/* Individual Cards Section */}
            <div className="flex flex-col gap-4">
              <Card className="shadow-lg" title="Individual Cards">
                <div className=" w-full gap-4">
                  {paymentCards?.personalCards?.length === 0 ? (
                    <p className="text-sm md:text-base text-center italic font-medium text-gray-500">
                      No cards found
                    </p>
                  ) : (
                    paymentCards?.personalCards?.map((paymentCard, index) => (
                      <PaymentCard
                        fetchData={fetchData}
                        clientId={clientId}
                        key={index}
                        cardId={paymentCard.id}
                        brand={paymentCard.card.brand}
                        cardNo={`****${paymentCard.card.last4}`}
                        cardExp={`${paymentCard.card.exp_month}/${paymentCard.card.exp_year}`}
                        CusId={stripeCusId}
                        onClose={() => setIsPopUpOpen(false)}
                        defaultcard={
                          clientInfo?.defaultPaymentMethod === paymentCard.id
                        }
                        method={paymentCard}
                      />
                    ))
                  )}
                </div>
                <button
                  className={clsx(
                    "w-full  sm:w-fit px-6 py-2 text-sm md:text-base font-medium rounded-md shadow disabled:bg-gray-400 disabled:cursor-not-allowed",
                    company?.stripeActivation === false && "bg-gray-300"
                  )}
                  onClick={
                    company?.stripeActivation === false ? undefined : togglePopup
                  }
                  disabled={!company?.stripeActivation}
                >
                  + Add Card
                </button>
              </Card>
            </div>

            {/* Corporate Cards Section */}
            <div className="flex flex-col gap-4">
              <Card className="shadow-lg" title="Corporate Cards">
                <div className="w-full gap-4">
                  {paymentCards?.corporateCards?.length === 0 ? (
                    <p className="text-sm md:text-base text-center italic font-medium text-gray-500">
                      No cards found
                    </p>
                  ) : (
                    paymentCards?.corporateCards?.map((paymentCard, index) => (
                      <PaymentCard
                        fetchData={fetchData}
                        clientId={clientId}
                        key={index}
                        cardId={paymentCard.id}
                        brand={paymentCard.card.brand}
                        cardNo={`****${paymentCard.card.last4}`}
                        cardExp={`${paymentCard.card.exp_month}/${paymentCard.card.exp_year}`}
                        CusId={stripeCusId}
                        onClose={() => setIsPopUpOpen(false)}
                        defaultcard={
                          clientInfo?.defaultPaymentMethod === paymentCard.id
                        }
                        method={paymentCard}
                      />
                    ))
                  )}
                </div>
                <button
                  className={clsx(
                    "w-full sm:w-fit px-6 py-2 text-sm md:text-base font-medium rounded-md shadow disabled:bg-gray-400 disabled:cursor-not-allowed",
                    company?.stripeActivation === false && "bg-gray-300"
                  )}
                  onClick={
                    company?.stripeActivation === false ? undefined : togglePopup2
                  }
                  disabled={!company?.stripeActivation}
                >
                  + Add Card
                </button>
              </Card>
            </div>
          </div>
        )}

        {/* {company?.selectedGateway === "Clover" && (
          <div className="flex flex-col gap-6 w-full">
            <div className="flex flex-col gap-4">
              <Card className="shadow-lg" title="Individual Cards">
                <div className=" w-full gap-4">
                  {cloverCards?.length === 0 ? (
                    <p className="text-lg text-center italic font-bold mt-4 mb-2">
                      No payment method found
                    </p>
                  ) : (
                    cloverCards?.map((cloverCard, index) => (
                      <div className="mb-4 flex justify-start items-center w-fit">
                        <CreditCard
                          key={index}
                          number={`${cloverCard?.first6}** **** ${cloverCard?.last4}`}
                          name={`${clientInfo.firstName || "Cardholder"} ${clientInfo.lastName || "Name"}`}
                          expiry={`${cloverCard?.expirationDate?.slice(0, 2) || ""}/${cloverCard?.expirationDate?.slice(2) || ""}`}
                          cvc="•••"
                          focused={""}
                        />
                      </div>

                    ))
                  )}
                </div>
                <button
                  className={`border text-sm rounded-md px-6 py-2 inline-block ${cloverCards?.length > 0 ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                    }`}
                  onClick={cloverCards?.length > 0 ? undefined : showModal}
                  // disabled={!company?.stripeActivation}
                >
                  + Add Card
                </button>
              </Card>
            </div>
          </div>
        )} */}

        {company?.selectedGateway === "None" && (
          <div className="flex flex-col items-center justify-center w-full p-6 rounded-lg border border-red-300 shadow relative bg-[linear-gradient(45deg,#fcb6b6_25%,#ffffff_25%,#ffffff_50%,#fcb6b6_50%,#fcb6b6_75%,#ffffff_75%,#ffffff)] bg-[size:40px_40px]">
            <p className="text-red-700 font-semibold text-lg text-center">
              Unfortunately, {company?.name} doesn't have a payment gateway selected.
            </p>
            <p className="text-gray-700 mt-2 text-center">
              You are unable to add cards until a payment gateway is configured.
            </p>
          </div>
        )}




        {/* Stripe Modal */}
        {openModal && stripepromise && (
          <Elements stripe={stripepromise}>
            <AddCard
              fetchData={fetchData}
              CusId={stripeCusId}
              ConnAccId={stripeConnAccount}
              Cardtype={cardtype}
              clientId={clientId}
              onClose={handleModalClose}
              setCard={(newCard) =>
                cardtype === "individual"
                  ? setIndividualCards((cards) => [...cards, newCard])
                  : setCorporateCards((cards) => [...cards, newCard])
              }
            />
          </Elements>
        )}

        {/* Clover Modal */}
        {/* <Modal
          title="Add Card"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          centered
        >
          <PaymentForm
            customer={clientInfo}
            merchant={company}
            onClose={handleCancel}
          />
        </Modal> */}
      </div>
    </>
  );
};

export default Cards;
